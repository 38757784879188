<template>
  <div>
    <b-card>
      <strong>Atenção!</strong>
      <br>
      Os dados dos pacientes ficam disponíveis durante três meses após a solicitação.
    </b-card>
    <b-card>
      <div class="d-flex align-items-center">
        <img
          :src="logoProgram"
          width="60rem"
          class="mr-2"
        >
        <h4>
          Histórico de itens liberados ({{ nameProgram }})
        </h4>
      </div>
    </b-card>
    <div>
      <div
        v-for="history in histories"
        :key="history.id"
      >
        <b-card
          no-body
          class="mb-2 pb-2 "
        >
          <template v-if="history.tokens_history_info.length !== 0">
            <h4 class="my-2 pl-2 pr-2">
              {{ history.item_info.name }}
              <span v-if="history.item_info.type === 'KIT'">
                {{ kitResume(history.item_info) }}
              </span>
              <b-button
                v-if="history.item_info.type === 'KIT'"
                :id="`popover-kit-${history.item_info.id}`"
                variant="outline-light"
                class="btn-no-hover rounded-circle p-0"
              >
                <feather-icon
                  icon="InfoIcon"
                  stroke="blue"
                  size="16"
                />
              </b-button>
              <b-popover
                v-if="history.item_info.type === 'KIT'"
                :target="`popover-kit-${history.item_info.id}`"
                variant="primary"
                triggers="click blur"
                custom-class="kit-popover"
              >
                <template #title>
                  <h6 class="text-white m-0">
                    {{ history.item_info.name }}
                  </h6>
                </template>
                <p
                  v-for="item in history.item_info.item_childs_info"
                  :key="item.id"
                >
                  {{ nameFormatted(item) }}
                </p>
              </b-popover>

            </h4>
            <b-table
              class="position-relative"
              responsive
              :items="history.tokens_history_info"
              :fields="fields"
            >
              <!-- Column: Action -->
              <template #cell(action)="data">
                <div
                  v-if="data.item.status === 'ACTIVE'"
                  class="col-8 p-0"
                >
                  <feather-icon
                    size="18"
                    icon="Trash2Icon"
                    color="#5d6381"
                    style="cursor: pointer"
                    @click="show=true, tokenId=data.item.token_id"
                  />
                </div>
                <div
                  v-else
                  class="col-8 p-0"
                >
                  <feather-icon
                    size="18"
                    icon="Trash2Icon"
                    color="#cad1db"
                  />
                </div>
              </template>
            </b-table>
          </template>
        </b-card>
      </div>
    </div>

    <b-modal
      v-model="show"
      centered
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      ok-variant="danger"
      ok-title="Excluir"
      hide-header
      @ok="deleteToken"
    >

      <b-card-text class="text-center">
        <feather-icon
          icon="AlertCircleIcon"
          stroke="red"
          size="60"
        />
        <div class="mt-2">
          <strong>Atenção!</strong>
        </div>
        <div class="mt-2">
          Deseja excluir esta liberação de item?
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import {
  BCard,
  BTable,
  BCardText,
  BPopover,
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { breakpointsBootstrapV5, useBreakpoints } from '@vueuse/core'

export default {
  components: {
    BCard,
    BTable,
    BCardText,
    BPopover,
    BButton,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  setup() {
    const breakpoints = useBreakpoints(breakpointsBootstrapV5)
    const sm = breakpoints.smallerOrEqual('sm')
    return {
      sm,
    }
  },

  data() {
    return {
      show: false,
      tokenId: null,
      histories: [],
      table: [],
      itens: [],
      fields: [
        {
          key: 'action',
          sortable: false,
          label: 'Ação',
        },
        {
          key: 'status',
          sortable: true,
          label: 'Situação do cadastro',
          formatter: value => {
            if (value === 'ACTIVE') {
              return 'Pendente'
            }
            return 'Concluído'
          },
        },
        {
          key: 'member_full_name',
          sortable: true,
          label: 'Nome Completo',
        },
        {
          key: 'member_identification',
          sortable: true,
          label: 'Celular ou e-mail',
          formatter: value => {
            let identification = ''
            if (value) {
              const re = /\S+@\S+\.\S+/
              if (re.test(value)) {
                identification = value
              } else {
                identification = `(${value.substr(2, 2)}) ${value.substr(4, 5)}-${value.substr(9, 4)}`
              }
            }
            return identification
          },
        },
        {
          key: 'created_at',
          sortable: true,
          label: 'Data de cadastro',
          formatter: value => {
            let date = ''
            date = moment(value).format('DD/MM/YYYY')
            return date
          },
        },
      ],

    }
  },

  computed: {
    logoProgram() {
      return this.$store.getters['stock/logoProgram']
    },
    nameProgram() {
      return this.$store.getters['stock/nameProgram']
    },
  },

  mounted() {
    this.listHistory()
  },

  methods: {
    listHistory() {
      this.$store.dispatch('stock/listHistory')
        .then(response => {
          this.histories = response.data.data.stocks_history_info
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    fullName(value) {
      return `${value.member_last_name} ${value.member_last_name}`
    },
    formatDateBR(value) {
      return moment(String(value).format('DD/MM/YYYY'))
    },
    deleteToken() {
      const dataForm = {
        token_id: this.tokenId,
      }
      this.$store.dispatch('stock/deleteToken', dataForm)
        .then(() => {
          this.listHistory()
          this.sucessDeleteToken()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível excluir a liberação',
              text: 'Algo deu errado, tente novamente em alguns minutos',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    sucessDeleteToken() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Liberação excluída com sucesso',
          text: 'A liberação do item foi excluída e o saldo retornou ao seu estoque',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    kitResume(items) {
      const services = items.item_childs_info.filter(item => item.type === 'SERVICE')
      const products = items.item_childs_info.filter(item => item.type !== 'SERVICE')
      const servicesText = services.length > 0 ? `${services.length} ${services.length > 1 ? 'serviços' : 'serviço'}` : ''
      const productsText = products.length > 0 ? `${products.length} ${products.length > 1 ? 'itens' : 'item'}` : ''
      return (services.length > 0 && products.length > 0) ? `(${servicesText} e ${productsText})` : `(${servicesText}${productsText})`
    },
    nameFormatted(item) {
      let quantityLabel = ''
      if (item.quantity > 1) {
        quantityLabel = item.type === 'SERVICE' ? 'Aplicações' : 'Unidades'
      } else {
        quantityLabel = item.type === 'SERVICE' ? 'Aplicação' : 'Unidade'
      }

      return `${item.name} (${item.quantity} ${quantityLabel})`
    },
  },
}
</script>

<style scoped>
  ::v-deep .modal-footer {
    justify-content: center;
  }
  .kit-popover p {
  margin-bottom: 5px;
}

.kit-popover .feather {
  margin-right: 5px;
}
</style>
